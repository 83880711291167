import React, { useEffect } from 'react'
import * as Chakra from '@chakra-ui/react'
import IndustriesOne from '../../images/industries-one.jpg'
import IndustriesTwo from '../../images/industries-two.jpg'
import IndustriesThree from '../../images/industries-three.jpg'
import IndustriesFour from '../../images/industries-four.jpg'
import IndustriesFive from '../../images/industries-five.jpg'
import IndustriesSix from '../../images/industries-six.jpg'
import IndustriesSlide from '../industries-slide'
import { LeftText } from '../text-layouts/left-text'
import ScrollableSection from '../dynamic-anchors'
import { useInView } from 'react-intersection-observer'
import { uiState } from '../state-machines/ui-state'
import { SlideAndScroll } from '../scroll-animations/slide-and-scroll'

const Industries = props => {
  const sidePanelRef = React.useRef()

  let { ref, inView } = useInView({
    threshold: 0,
    initialInView: false,
    triggerOnce: false,
  })

  useEffect(() => {
    if (inView) {
      uiState.changeLogoColor('#0430D2')
    }
  }, [inView])

  return (
    <ScrollableSection
      hash={'industries'}
      meta={{ title: 'Industries • TechStreamLab' }}
    >
      <Chakra.Box>
        <Chakra.Box
          h={{ base: 'auto', lg: '600vh' }}
          maxH={{ base: 'none', lg: '600vh' }}
          ref={sidePanelRef}
        >
          <Chakra.Flex
            flexDir={{ base: 'column', lg: 'row' }}
            maxH={{ base: 'none' }}
            h={{ base: 'auto' }}
            position={{
              base: 'relative',
              lg: 'sticky',
            }}
            top={{ lg: '0' }}
            ref={ref}
          >
            <Chakra.Flex
              bg="#F5F5F5"
              width={{
                base: '100%',
                lg: '45vw',
              }}
              marginLeft={{ lg: '-5vw' }}
              paddingLeft={{ lg: '5vw' }}
            >
              <LeftText
                titleSm="Industries"
                pageNum="04"
                totalPages="07"
                titleLg="Industries."
                body="TechStreamLab follows a hybrid
                investment strategy that aims to
                leverage innovative technologies in
                emerging sectors."
                fullWidth
                isLight
                isSticky
                isLeft
                position={{
                  base: 'relative',
                  lg: 'sticky',
                }}
                top={{ lg: '0' }}
                height={{ lg: 'max-content' }}
              />
            </Chakra.Flex>

            <SlideAndScroll
              sidePanelRef={sidePanelRef}
              inView={inView}
              w="100%"
              flex="1"
              flexDir={{ base: 'column', lg: 'row' }}
            >
              <IndustriesSlide
                img={IndustriesOne}
                id={'04—A'}
                title={'Healthcare Technologies'}
                to="/industries#healthcare-technologies"
              />
              <IndustriesSlide
                img={IndustriesTwo}
                id={'04—B'}
                title={'AI & Automation'}
                to="/industries#ai-automation"
              />
              <IndustriesSlide
                img={IndustriesThree}
                id={'04—C'}
                title={'Big Data & Analytics'}
                to="/industries#big-data-analytics"
              />
              <IndustriesSlide
                img={IndustriesFour}
                id={'04—D'}
                title={'Financial Technologies'}
                to="/industries#financial-technology"
              />
              <IndustriesSlide
                img={IndustriesFive}
                id={'04—E'}
                title={'Agriculture & Sustainability'}
                to="/industries#agriculture-sustainability"
              />
              <IndustriesSlide
                img={IndustriesSix}
                id={'04—F'}
                title={'Blockchain & Decentralization'}
                to="/industries#blockchain-decentralization"
              />
            </SlideAndScroll>
          </Chakra.Flex>
        </Chakra.Box>
      </Chakra.Box>
    </ScrollableSection>
  )
}

export default Industries
