import React, { useEffect } from 'react'
import * as Chakra from '@chakra-ui/react'

import PageInfo from '../text-layouts/page-info'
import Email from '../text-layouts/email'
import BlueDots from '../animations/blue-dots'
import ScrollableSection from '../dynamic-anchors'
import { useInView } from 'react-intersection-observer'
import { uiState } from '../state-machines/ui-state'

function ContactContent() {
  return (
    <Chakra.Box paddingLeft={{ lg: '5vw' }}>
      <PageInfo
        numCurrent="07"
        numTotal="07"
        title="Contact"
        color="#fff"
      />
      <Chakra.Text
        mt={{
          base: '38px',
          lg: '38px',
          '2xl': '100px',
        }}
        mb={{ base: '40px', lg: '10px', '2xl': '20px' }}
        fontSize={{
          base: '42px',
          lg: '80px',
          '2xl': '120px',
        }}
        textStyle="aktivGrotesk"
      >
        Contact.
      </Chakra.Text>
      <Chakra.Divider
        borderColor="#fff"
        opacity="1"
        noshade="noshade"
        w="100%"
        position="relative"
      />
      <Chakra.Text
        mt={{ base: '8px', md: '16px', lg: '40px' }}
        fontSize={{
          base: '8px',
          md: '12px',
          lg: '18px',
        }}
        textTransform="uppercase"
        textStyle="basisGrotesque"
      >
        More information
      </Chakra.Text>

      <Email>info@techstreamlab.com</Email>
      <Chakra.Text
        mt={{ base: '8px', md: '16px', lg: '40px' }}
        fontSize={{
          base: '8px',
          md: '12px',
          lg: '18px',
        }}
        textTransform="uppercase"
        textStyle="basisGrotesque"
      >
        Press inquiries
      </Chakra.Text>
      <Email>press@techstreamlab.com</Email>
    </Chakra.Box>
  )
}

const Contact = props => {
  const scrollDirection = uiState.useGlobalState(
    gs => gs.scrollDirection
  )
  let { ref, inView } = useInView({
    threshold: scrollDirection === 'up' ? 0 : 0.5,
    initialInView: false,
    triggerOnce: false,
  })

  useEffect(() => {
    if (inView) {
      uiState.changeLogoColor('#fff')
    }
  }, [inView])

  return (
    <ScrollableSection
      hash={'contact'}
      meta={{ title: 'Contact • TechStreamLab' }}
    >
      <Chakra.Box
        h={{ base: 'auto' }}
        maxH="none"
        marginLeft={{ lg: '-5vw' }}
        minH={{ lg: '100vh' }}
      >
        <Chakra.Flex
          ref={ref}
          d={{ base: 'none', lg: 'flex' }}
          height="auto"
          flexDirection={{ base: 'row' }}
          minH={{ lg: '100vh' }}
        >
          <Chakra.Flex
            pos="relative"
            w="calc(100% + 5vw)"
            minH={{ lg: '100vh' }}
          >
            <BlueDots />
            <Chakra.Flex
              w="100%"
              d={{ base: 'none', lg: 'flex' }}
              flexDirection="column"
              px="max(4vw, 40px)"
              pos="relative"
              py={{ base: '40%', lg: '24vh' }}
              maxW="min(1580px, 55%)"
              color="#fff"
            >
              <ContactContent />
            </Chakra.Flex>
          </Chakra.Flex>
        </Chakra.Flex>
        <Chakra.Flex
          flexDir="column"
          minH={{ base: '0', lg: '50vh' }}
          d={{ base: 'flex', lg: 'none' }}
          p="40px 40px 120px 40px"
          bg="#002DD1"
          color="#fff"
          alignItems="center"
        >
          <Chakra.Box w="100%">
            <ContactContent />
          </Chakra.Box>
        </Chakra.Flex>
      </Chakra.Box>
    </ScrollableSection>
  )
}

export default Contact
