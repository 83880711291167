import React from 'react'
import * as Chakra from '@chakra-ui/react'

const Email = ({ children, ...props }) => {
  return (
    <Chakra.Text
      mt={{ base: '10px', md: '10px', lg: '20px' }}
      fontSize={{
        base: '15px',
        lg: '32px',
        '2xl': '54px',
      }}
      mb={{ lg: '10px', '2xl': '25px' }}
      {...props}
      textStyle="basisGrotesque"
      letterSpacing="0"
    >
      <Chakra.Text
        p={{ lg: '3' }}
        ml={{ lg: '-3' }}
        as="span"
        bg="linear-gradient(to left, transparent 50%, #fff 50%) right"
        bgSize="201%"
        mt={{ base: '100px', md: 'none' }}
        _hover={{
          lg: { bgPos: 'left', color: '#002DD1' },
        }}
        transition="all .25s ease-out"
      >
        {children}
      </Chakra.Text>
    </Chakra.Text>
  )
}

export default Email
