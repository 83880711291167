import React from 'react'
import { motion } from 'framer-motion'

function ArrowDownIcon() {
  const bounceTransition = {
    y: {
      duration: 0.4,
      yoyo: Infinity,
      ease: 'easeOut',
    },
  }

  return (
    <div
      style={{
        width: '2rem',
        height: '2rem',
        display: 'flex',
        justifyContent: 'space-around',
      }}
    >
      <motion.span
        transition={bounceTransition}
        animate={{
          y: ['30%', '-30%'],
        }}
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="19.081"
          height="47.728"
          viewBox="0 0 19.081 47.728"
          style={{ height: '32px' }}
        >
          <g
            fill="#fff"
            data-name="Group 70"
            transform="rotate(90 9.54 9.54)"
          >
            <path
              d="M0 0H46.236V2.983H0z"
              data-name="Rectangle 33"
              transform="translate(0 8.049)"
            ></path>
            <path
              d="M0 0H13.498V3.375H0z"
              data-name="Rectangle 34"
              transform="rotate(45 19.091 46.091)"
            ></path>
            <path
              d="M0 0H13.498V3.374H0z"
              data-name="Rectangle 35"
              transform="rotate(-45 38.05 -34.862)"
            ></path>
          </g>
        </svg>
      </motion.span>
    </div>
  )
}

export default ArrowDownIcon
