import React from 'react'
import * as Chakra from '@chakra-ui/react'
import { Link } from 'gatsby'
import PlusIcon from './icons/plus-icon'

const CompaniesSliderChild = ({
  bgImg,
  letter,
  title,
  to,
  url,
  ...props
}) => {
  return (
    <Chakra.Flex
      bgColor="#000A"
      _before={{
        content: '""',
        height: '100%',
        width: '100%',
        position: 'absolute',
        backgroundImage: `url(${bgImg})`,
        zIndex: '-1',
        backgroundSize: 'cover',
      }}
      _hover={{
        backgroundColor: '#0004',
      }}
      transition="0.3s"
      position="relative"
      bgPos="center center"
      bgSize="cover"
      minH={{ base: '337px', lg: '60vh' }}
      w="100%"
      alignItems="flex-end"
      {...props}
    >
      <Chakra.Box
        w={{ base: '100%', lg: '65%' }}
        fontSize={{ base: '14px', lg: '32px' }}
        color="#fff"
        p={{
          base: '40px 40px 60px 40px',
          lg: '65px 0 65px 75px',
        }}
      >
        05—{letter}
        <Chakra.Divider
          borderColor="#fff"
          opacity="1"
          noshade="noshade"
          w="100%"
          position="relative"
          mt={{ base: '8px', lg: '10px' }}
          mb="35px"
        />
        <Chakra.Text
          maxW={{ base: '132px', lg: '320px' }}
          fontSize={{
            base: '19px',
            lg: '46px',
          }}
        >
          {title}
        </Chakra.Text>
        <Chakra.Link
          href={url}
          isExternal
        >
          <Chakra.Button
            backgroundColor="#002DD1"
            _hover={{
              color: "#002DD1",
              backgroundColor: "#fff"
            }}
          >
            Open website
          </Chakra.Button>
        </Chakra.Link>
        <Link to={to}>
          <Chakra.Flex
            d={{ base: 'none', lg: 'flex' }}
            mt="100px"
            letterSpacing=".1em"
            fontSize="18px"
            alignItems="center"
            textTransform="uppercase"
          >
            <PlusIcon />
            <Chakra.Text
              base={{ d: 'none', lg: 'block' }}
              ml="16px"
              as="span"
            >
              Learn More
            </Chakra.Text>
          </Chakra.Flex>
        </Link>
      </Chakra.Box>
    </Chakra.Flex>
  )
}

export default CompaniesSliderChild
