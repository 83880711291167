import React from 'react'
import * as Chakra from '@chakra-ui/react'
import { Link } from 'gatsby'

const IndustriesSlide = ({ img, id, title, to }) => {
  return (
    <Chakra.Flex
      flex="1"
      bgSize="cover"
      as={Link}
      to={to}
      minW={{ lg: '100%' }}
      bgColor="#0009"
      _before={{
        content: '""',
        height: '100%',
        width: '100%',
        position: 'absolute',
        backgroundImage: `url(${img})`,
        zIndex: '-1',
        backgroundSize: 'cover',
      }}
      _hover={{
        backgroundColor: '#0004',
      }}
      transition="0.3s"
    >
      <Chakra.Flex
        p={{ base: '44px', lg: 'max(95px, 6vw)' }}
        h={{ base: '260px', lg: '100%' }}
        flexDir="column"
        justify="flex-end"
        w="100%"
        color="#fff"
        bg="rgba(0, 0, 0, 0.6)"
      >
        <Chakra.Box
          fontSize={{
            base: '20px',
            lg: 'max(20px, 1vw)',
          }}
        >
          {id}
          <Chakra.Divider
            borderColor="#fff"
            opacity="1"
            noshade="noshade"
            w="100%"
            position="relative"
            mt={{ base: '8px', lg: '35px' }}
            mb="35px"
          />
          <Chakra.Text
            fontSize={{
              base: '32px',
              lg: 'max(32px, 2.2vw)',
            }}
          >
            {title}
          </Chakra.Text>
        </Chakra.Box>
      </Chakra.Flex>
    </Chakra.Flex>
  )
}

export default IndustriesSlide
