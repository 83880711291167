import React from 'react'
import * as Chakra from '@chakra-ui/react'

export default class HeadingTextAnimateSplit extends React.Component {
  render() {
    let { line1, line2, ...otherProps } = this.props

    return (
      <Chakra.Box
        textStyle="aktivGrotesk"
        className="heading--item"
        {...otherProps}
      >
        <Chakra.Text lineHeight="1" as="div">
          {line1}
          <br />
          {line2}
        </Chakra.Text>
      </Chakra.Box>
    )
  }
}
