import React from 'react'
import * as Chakra from '@chakra-ui/react'
import Home from '../components/slides/home'
import About from '../components/slides/about'
import Services from '../components/slides/services'
import Industries from '../components/slides/industries'
import Companies from '../components/slides/companies'
import Leadership from '../components/slides/leadership'
import Contact from '../components/slides/contact'
import { Helmet } from 'react-helmet'

const Index = props => {
  return (
    <Chakra.Box
      width={{ base: '100%', lg: 'calc(100vw - 12vw)' }}
      marginLeft={{ lg: '5vw' }}
    >
      <Helmet>
        <title>Overview • TechStreamLab</title>
        <meta name="description" content="Techstream Lab is a family of tech-focused companies in nascent industries with the aim of pioneering and redefining their respective sectors." />
      </Helmet>
      <Home />
      <About />
      <Services />
      <Industries />
      <Companies />
      <Leadership />
      <Contact />
    </Chakra.Box>
  )
}

export default Index
