import React, { useEffect } from 'react'
import * as Chakra from '@chakra-ui/react'
import LeadershipOne from '../../images/leadership-one.jpg'
import LeadershipTwo from '../../images/leadership-two.jpg'
import LeadershipSliderChild from '../leadership-slider-child'
import { LeftText } from '../text-layouts/left-text'
import ScrollableSection from '../dynamic-anchors'
import { useInView } from 'react-intersection-observer'
import { uiState } from '../state-machines/ui-state'
import { SlideIntoView } from '../scroll-animations/slide-into-view'

const Leadership = props => {
  const scrollDirection = uiState.useGlobalState(
    gs => gs.scrollDirection
  )

  const sidePanelRef = React.useRef()

  let { ref, inView } = useInView({
    threshold: scrollDirection === 'up' ? 0 : 0.5,
    initialInView: false,
    triggerOnce: false,
  })

  useEffect(() => {
    if (inView) {
      uiState.changeLogoColor('#0430D2')
    }
  }, [inView])

  return (
    <ScrollableSection
      hash={'leadership'}
      meta={{ title: 'Leadership • TechStreamLab' }}
    >
      <Chakra.Box
        h={{ base: 'auto' }}
        maxH={{ base: 'none' }}
        ref={sidePanelRef}
      >
        <Chakra.Flex
          flexDir={{ base: 'column', lg: 'row' }}
          maxH={{ base: 'none' }}
          h={{ base: 'auto' }}
          position={{ base: 'relative', lg: 'sticky' }}
          top={{ lg: '0' }}
        >
          <Chakra.Flex
            bg="#FFF"
            width={{
              base: '100%',
              lg: '50vw',
            }}
            marginLeft={{ lg: '-5vw' }}
            paddingLeft={{ lg: '5vw' }}
            height={{ lg: '150vh' }}
          >
            <LeftText
              ref={ref}
              titleSm="Leadership"
              pageNum="06"
              totalPages="07"
              titleLg="Leadership."
              body="Headquartered in Madrid, Spain the
                TSL team is comprised of a group of like
                minded enthusiasts that aim to build
                technologies that act as a catalyst for
                major change."
              fullWidth
              isLight
              isSticky
              position={{
                base: 'relative',
                lg: 'sticky',
              }}
              top={{ lg: '0' }}
              height={{ lg: 'max-content' }}
              isLeft
            />
          </Chakra.Flex>

          <SlideIntoView
            d="flex"
            flexDir="column"
            inView={inView}
            sidePanelRef={sidePanelRef}
            height="100%"
            span="43vw"
          >
            <Chakra.Flex
              d={{ base: 'none', lg: 'flex' }}
              bg="#0430D2"
              color="#fff"
              h={{ lg: 'max(120px, 12vh)' }}
              w="100%"
              top="0"
              alignItems="center"
              justify="space-between"
            >
              <Chakra.Text
                fontSize="18px"
                letterSpacing="0.1em"
                textTransform="uppercase"
                textStyle="basisGrotesque"
                pl={{ base: '44px', lg: '95px' }}
              >
                Leadership Team
              </Chakra.Text>
            </Chakra.Flex>
            <Chakra.Flex
              w="100%"
              flex="1"
              flexDir={{ base: 'column', lg: 'row' }}
              height={{
                lg: 'calc(100vh - max(120px, 6vw))',
              }}
            >
              <LeadershipSliderChild
                img={LeadershipOne}
                name={'Máté Kovács'}
                title={'Co-founder, CEO'}
                bgColor="#EBEBEB"
                url="https://www.linkedin.com/in/mate-balazs-kovacs/"
              />
              <LeadershipSliderChild
                img={LeadershipTwo}
                name={'István Fazekas'}
                title={'Co-founder'}
                bgColor="#FAFAFA"
                url="https://www.linkedin.com/in/istvan-fazekas-management/"
              />
            </Chakra.Flex>
          </SlideIntoView>
        </Chakra.Flex>
      </Chakra.Box>
    </ScrollableSection>
  )
}

export default Leadership
