import * as React from 'react'
import { useWindowSize } from '@react-hook/window-size'

export const useAnimatableLayoutEffect = (
  effect,
  deps = []
) => {
  const [windowWidth, windowHeight] = useWindowSize()

  React.useLayoutEffect(() => {
    if (typeof window !== 'undefined') {
      effect()

      let timer = setInterval(() => {
        effect()
      }, 1000)

      return () => {
        clearInterval(timer)
      }
    }
  }, [...deps, windowWidth, windowHeight])
}
