import * as React from 'react'
import * as Chakra from '@chakra-ui/react'
import { SlideIntoViewVertical } from './scroll-animations/slide-into-view-vertical'
import { useInView } from 'react-intersection-observer'
import { uiState } from './state-machines/ui-state'
import { getPositionOfElement } from './animation-helpers/get-position-of-element'
import { useAnimatableLayoutEffect } from './animation-helpers/use-animatable-layout-effect'
import { useWindowSize } from '@react-hook/window-size'

const ServicesSliderChild = ({
  img,
  video,
  letter,
  title,
  description,
  defaultInView = false,
  slideNumber,
  sidePanelRef,
  ...props
}) => {
  const [windowWidth, windowHeight] = useWindowSize()
  const [pageTop, setPageTop] = React.useState(0)

  const scrollY = uiState.useGlobalState(
    gs => gs.scrollY
  )

  useAnimatableLayoutEffect(() => {
    if (sidePanelRef && sidePanelRef.current) {
      let pos = getPositionOfElement(sidePanelRef)
      setPageTop(pos.pageTop)
    }
  }, [sidePanelRef, windowHeight])

  const inView = React.useMemo(() => {
    return (
      scrollY >
      pageTop +
        (slideNumber - 1) * windowHeight -
        windowHeight
    )
  }, [scrollY, windowWidth, windowHeight])

  return (
    <Chakra.Box height={{ lg: '100vh' }}>
      <Chakra.Box
        ref={sidePanelRef}
        height={{ lg: '100%' }}
      >
        <SlideIntoViewVertical
          defaultInView={defaultInView}
          inView={inView}
        >
          <Chakra.Box
            pos={{ base: 'static', lg: 'relative' }}
            w="100%"
            minH={{ lg: '100vh' }}
            h={{ lg: '100vh' }}
            {...props}
          >
            <Chakra.Flex h={{ lg: '50vh' }}>
              <Chakra.Image
                objectFit="cover"
                width="100%"
                src={img}
              />
            </Chakra.Flex>
            <Chakra.Flex
              p={{
                base: '40px 40px 40px 40px',
                lg: '10vh 4vw',
              }}
              fontSize={{
                base: '20px',
                lg: 'max(20px, 1vw)',
              }}
              pos={{ base: 'static', lg: 'relative' }}
              alignItems="flex-start"
              flexDirection="column"
              color="#fff"
              marginLeft={{ lg: '5vw' }}
              minH={{ lg: '50vh' }}
              justifyContent="center"
              {...props}
            >
              03—{letter}
              <Chakra.Divider
                borderColor="#fff"
                opacity="1"
                noshade="noshade"
                w="100%"
                position="relative"
                my="35px"
              />
              <Chakra.Text
                mb="28px"
                fontSize={{
                  base: '32px',
                  lg: '20px',
                  xl: '24px',
                  '2xl': '36px',
                }}
              >
                {title}
              </Chakra.Text>
              <Chakra.Text
                fontSize={{
                  base: '14px',
                  xl: '18px',
                  '2xl': '22px',
                }}
              >
                {description}
              </Chakra.Text>
            </Chakra.Flex>
          </Chakra.Box>
        </SlideIntoViewVertical>
      </Chakra.Box>
    </Chakra.Box>
  )
}

export default ServicesSliderChild
