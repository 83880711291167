import React from 'react'
import * as Chakra from '@chakra-ui/react'
import iconLinkedin from '../images/linkedin_icon.png';

const LeadershipSliderChild = ({
  img,
  name,
  title,
  bgColor,
  url,
  ...props
}) => {
  return (
    <Chakra.Box width="100%">
      <Chakra.Flex
        flexDir={{ base: 'column', lg: 'column' }}
        flex="1"
        width="100%"
        height="100%"
        {...props}
      >
        <Chakra.Box
          w={{ base: '100%' }}
          objectFit="cover"
          objectPosition="0 30%"
          minHeight={{ base: '700px', lg: 'unset' }}
          backgroundImage={`url(${img})`}
          backgroundPosition="center 20%"
          backgroundSize="cover"
          height="100%"
        />
        <Chakra.Flex
          justify="flex-end"
          flexDir="column"
          bg={bgColor}
          w="100%"
          bottom="0"
          p={{
            base: '40px 40px 60px 40px',
            lg: '40px 95px 60px 95px',
          }}
        >
          <Chakra.Text
            color="#15151D"
            my="20px"
            fontSize={{ base: '26px', lg: '32px' }}
            textStyle="aktivGrotesk"
          >
            {name}
          </Chakra.Text>
          <Chakra.Divider
            borderColor="#000"
            borderWidth="1px"
          />
          <Chakra.Text
            my="20px"
            color="#15151D"
            fontSize={{ base: '20px', lg: '22px' }}
          >
            {title}
          </Chakra.Text>
          <Chakra.Link
            href={url}
            isExternal
          >
            <Chakra.Image
              mt="-5px"
              boxSize='24px'
              objectFit='cover'
              src={iconLinkedin}
              alt='Linkedin'
            />
          </Chakra.Link>
        </Chakra.Flex>
      </Chakra.Flex>
    </Chakra.Box>
  )
}

export default LeadershipSliderChild
