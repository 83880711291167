export const getPositionOfElement = ref => {
  if (ref.current) {
    const {
      top,
      right,
      bottom,
      left,
      width,
      height,
      x,
      y,
    } = ref.current.getBoundingClientRect()

    let pageTop = window.pageYOffset + top
    let pageBottom = pageTop + height

    return {
      top,
      right,
      bottom,
      left,
      width,
      height,
      x,
      y,
      pageTop,
      pageBottom,
    }
  }
}
