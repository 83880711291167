import React, { useEffect } from 'react'
import * as Chakra from '@chakra-ui/react'
import LabEdge from '../../images/lab-edge.jpg'
import Bitcoin from '../../images/services_capital.jpg'
import Chess from '../../images/services_access.jpg'
import Budapest from '../../images/services_strategy.jpg'
import ServicesSliderChild from '../services-slider-child'
import { LeftText } from '../text-layouts/left-text'
import ScrollableSection from '../dynamic-anchors'
import { useInView } from 'react-intersection-observer'
import { uiState } from '../state-machines/ui-state'
import { SlideIntoView } from '../scroll-animations/slide-into-view'
import { SlideIntoViewReverse } from '../scroll-animations/slide-into-view-reverse'

const Services = props => {
  const sidePanelRef = React.useRef()

  let { ref, inView } = useInView({
    threshold: 0,
    initialInView: false,
    triggerOnce: false,
  })

  useEffect(() => {
    if (inView) {
      uiState.changeLogoColor('#fff')
    }
  }, [inView])

  return (
    <ScrollableSection
      hash={'services'}
      meta={{ title: 'Services • TechStreamLab' }}
    >
      <Chakra.Box
        h={{ base: 'auto', lg: 'auto' }}
        maxH={{ base: 'none' }}
        display="flex"
      >
        <Chakra.Flex
          flexDir={{
            base: 'column-reverse',
            lg: 'row',
          }}
          height={{ base: '100%', lg: '100%' }}
          maxH={{ base: 'none' }}
          width="100%"
          ref={sidePanelRef}
        >
          <Chakra.Box
            pos="relative"
            width={{ base: '100%', lg: '45vw' }}
            flexDir={{ base: 'column' }}
            paddingBottom="0vh"
            marginLeft={{ lg: '-5vw' }}
            ref={ref}
            height={{ lg: '300vh' }}
          >
            <SlideIntoViewReverse
              inView={inView}
              sidePanelRef={sidePanelRef}
            >
              <ServicesSliderChild
                img={Bitcoin}
                bg="#002DD1"
                letter="A"
                title="Capital Investing & Extraction"
                description="Our venture division provides direct capital
              injection opportunities from both our own
              partner team as well as our global partner
              network."
                defaultInView
                slideNumber={1}
                sidePanelRef={sidePanelRef}
              />
              <ServicesSliderChild
                img={Chess}
                bg="#13131B"
                letter="B"
                title="Political Accessibility & Business Development"
                description="A good idea isn’t enough to make a company successful – we provide our companies access to our longstanding political and business networks to build the necessary runway for global expansion."
                slideNumber={2}
                sidePanelRef={sidePanelRef}
              />
              <ServicesSliderChild
                img={Budapest}
                bg="#7E7E7B"
                letter="C"
                title="Active Strategy &
              Operational Support"
                description="Our team is comprised of leading entrepreneurs and past operators who know what it takes to bring a business to the upper echelons of success. We provide active strategy and operations support that accelerate your execution at scale.
  "
                slideNumber={3}
                sidePanelRef={sidePanelRef}
              />
            </SlideIntoViewReverse>
          </Chakra.Box>
          <SlideIntoView
            d="flex"
            pos="relative"
            bg="#f5f5f5"
            justify="space-between"
            alignItems="flex-end"
            flexDirection="column"
            h="auto"
            inView={inView}
            sidePanelRef={sidePanelRef}
          >
            <LeftText
              titleSm="Services"
              pageNum="03"
              totalPages="07"
              titleLg="Services."
              body="Our team is equipped with the appropriate skill sets and network access to springboard companies that are primed for scale and aggressive growth.
"
              isLight
              fullWidth
              paddingRight
            />

            <Chakra.Image
              d={{ base: 'none', lg: 'block' }}
              h="100%"
              w={{ lg: '5vw', '2xl': '10vw' }}
              objectFit="cover"
              objectPosition="0"
              src={LabEdge}
            />
          </SlideIntoView>
        </Chakra.Flex>
      </Chakra.Box>
    </ScrollableSection>
  )
}

export default Services
