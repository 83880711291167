import React from 'react'
import {
  motion,
  useSpring,
  useTransform,
  useViewportScroll,
} from 'framer-motion'
import * as Chakra from '@chakra-ui/react'
import ArrowRightIcon from '../icons/arrow-right-icon'
import { useAnimatableLayoutEffect } from '../animation-helpers/use-animatable-layout-effect'
import { getPositionOfElement } from '../animation-helpers/get-position-of-element'
import { uiState } from '../state-machines/ui-state'
import { getViewportHeight } from '../animation-helpers/get-viewport-height'

export const Helper = ({
  pageTop,
  pageBottom,
  viewportHeight,
  ...props
}) => {
  const { scrollY } = useViewportScroll()
  const slideScroll = useTransform(
    scrollY,
    [pageTop, pageBottom - viewportHeight - 500],
    [0, -(48 * (props.children.length - 1))]
  )
  const physics = {
    damping: 15,
    mass: 0.2,
    stiffness: 55,
  } // easing of smooth scroll
  const spring = useSpring(slideScroll, physics)
  const toPercentages = useTransform(
    spring,
    value => `${value}vw`
  )
  return (
    <Chakra.Flex
      as={motion.div}
      width="100%"
      flexDir="column"
      h={{ lg: 'calc(100vh - max(120px, 12vh))' }}
      css={{
        '@media screen and (max-width: 1279px)': {
          transform: 'none!important',
        },
      }}
      pos={{ lg: 'absolute' }}
      style={{ x: toPercentages }}
      {...props}
    />
  )
}

export function SlideAndScroll({
  inView,
  sidePanelRef,
  ...props
}) {
  const [pageTop, setPageTop] = React.useState(0)
  const [pageBottom, setPageBottom] = React.useState(1)
  const [
    viewportHeight,
    setViewportHeight,
  ] = React.useState(1)
  const scrollYViaState = uiState.useGlobalState(
    gs => gs.scrollY
  )

  useAnimatableLayoutEffect(() => {
    if (sidePanelRef && sidePanelRef.current) {
      let pos = getPositionOfElement(sidePanelRef)
      setPageTop(pos.pageTop)
      setPageBottom(pos.pageBottom)
      setViewportHeight(getViewportHeight())
    }
  }, [inView])

  return (
    <Chakra.Box
      width={{ base: '100%', lg: '48vw' }}
      top="0"
      height="100%"
      position={{ base: 'relative', lg: 'fixed' }}
      right={{ lg: '7vw' }}
      transition="1s cubic-bezier(0.71, 0.02, 0.12, 1.02)"
      transform={{
        base: 'translateX(0px)',
        lg:
          inView || scrollYViaState > pageTop
            ? 'translateX(0px)'
            : 'translateX(100%)',
      }}
      bg="black"
      overflow="hidden"
    >
      {pageBottom !== 1 && (
        <Helper
          pageTop={pageTop}
          pageBottom={pageBottom}
          viewportHeight={viewportHeight}
          {...props}
        />
      )}
      <Chakra.Flex
        d={{ base: 'none', lg: 'flex' }}
        bg="#002DD1"
        color="#fff"
        h={{ lg: 'max(120px, 12vh)' }}
        w="100%"
        position="absolute"
        bottom="0"
      >
        <Chakra.Flex
          w="75%"
          align="center"
          fontSize="max(20px, 1vw)"
          pl={{ base: '44px', lg: '95px' }}
        >
          <Chakra.Text
            as="span"
            textTransform="uppercase"
          >
            Industries
          </Chakra.Text>
        </Chakra.Flex>
        <Chakra.Flex
          justify="center"
          alignItems="center"
          w="25%"
          bg="#fff"
        >
          <ArrowRightIcon fill="rgba(0,0,0,.6)" />
        </Chakra.Flex>
      </Chakra.Flex>
    </Chakra.Box>
  )
}
