import React, { useEffect } from 'react'
import * as Chakra from '@chakra-ui/react'
import ArrowDownIcon from '../icons/arrow-down-icon'
import ArrowDownSmallIcon from '../icons/arrow-down-small-icon'
import ArrowRightIcon from '../icons/arrow-right-icon'
import PageInfo from '../text-layouts/page-info'
import BlueDots from '../animations/blue-dots'
import HeadingTextAnimateSplit from '../text-layouts/heading-text-animate-split'
import { OpacityIntersection } from '../scroll-animations/opacity-intersection'
import ScrollableSection from '../dynamic-anchors'
import { useInView } from 'react-intersection-observer'
import { uiState } from '../state-machines/ui-state'

const Home = props => {
  const scrollDirection = uiState.useGlobalState(
    gs => gs.scrollDirection
  )
  let { ref, inView } = useInView({
    threshold: scrollDirection === 'up' ? 0 : 0.5,
    initialInView: true,
    triggerOnce: false,
  })

  useEffect(() => {
    if (inView) {
      uiState.changeLogoColor('#fff')
    }
  }, [inView])

  const HomeContent = () => (
    <Chakra.Box px={{ lg: '5vw' }}>
      <PageInfo
        numCurrent="01"
        numTotal="07"
        title="Overview"
        color="#fff"
      />
      <Chakra.Text
        my={{
          base: '32px',
          md: '32px',
          lg: 'min(62px, 6vh)',
          '2xl': 'min(124px, 8vh)',
        }}
        fontSize={{
          base: '20px',
          lg: '24px',
          xl: '32px',
          '2xl': '32px',
        }}
      >
        By leveraging our technology infrastructure,
        global networks and business development team,
        we deploy and scale innovative and disruptive
        companies.
      </Chakra.Text>
    </Chakra.Box>
  )

  return (
    <ScrollableSection
      hash={'overview'}
      meta={{ title: 'Overview • TechStreamLab' }}
    >
      <Chakra.Box
        h={{ base: 'none', lg: '100%' }}
        maxH={{ base: 'none', lg: '100vh' }}
        minH="100vh"
      >
        <Chakra.Flex
          height={{ base: '50vh', lg: '100%' }}
          flexDirection={{ base: 'row' }}
          position="relative"
        >
          <Chakra.Box
            display="flex"
            width={{
              base: 'calc(100% - 60px)',
              lg: '40vw',
            }}
            ref={ref}
          >
            <Chakra.Box
              flex="1"
              pos="relative"
              zIndex="2"
              display="flex"
              alignItems="center"
              as={OpacityIntersection}
            >
              <HeadingTextAnimateSplit
                color="#fff"
                position="relative"
                fontSize={{
                  base: '54px',
                  lg: '90px',
                  xl: '130px',
                  '2xl': '180px',
                }}
                marginTop={{ lg: 'max(-230px, -30vh)' }}
                px="max(4vw, 40px)"
                line1="Reimagine"
                line2="innovation."
                width="max-content"
              />
              <Chakra.Flex
                bottom={{ base: '10%', lg: '12vh' }}
                left="max(4vw, 40px)"
                right="max(4vw, 40px)"
                pos="absolute"
                alignItems="center"
              >
                <Chakra.Box
                  d={{ base: 'none', md: 'block' }}
                >
                  <ArrowDownIcon />
                </Chakra.Box>
                <Chakra.Box
                  d={{ base: 'block', md: 'none' }}
                >
                  <ArrowDownSmallIcon />
                </Chakra.Box>

                <Chakra.Text
                  ml="38px"
                  color="#fff"
                  fontSize={{
                    base: '10px',
                    md: '14px',
                    lg: 'max(18px, 1vw)',
                  }}
                  textTransform="uppercase"
                  textStyle="basisGrotesque"
                  letterSpacing=".2em"
                >
                  Scroll Down
                </Chakra.Text>
              </Chakra.Flex>
            </Chakra.Box>
          </Chakra.Box>
          <Chakra.Box
            position={{ base: 'absolute', lg: 'fixed' }}
            top="0"
            width={{ base: '100%', lg: '48vw' }}
            right={{ lg: '7vw' }}
            height={{
              base: 'calc(100% + 16vh)',
              lg: '100%',
            }}
            marginTop={{ base: '-16vh', lg: '0' }}
          >
            <BlueDots />
          </Chakra.Box>
          <Chakra.Flex position="relative">
            <Chakra.Flex
              zIndex="502"
              d={{ base: 'none', lg: 'flex' }}
              textTransform="uppercase"
              pos="absolute"
              color="#fff"
              alignItems="center"
              width="100%"
            >
              <Chakra.Box
                display="flex"
                width="100%"
                alignItems="center"
                height={{ lg: 'min(152px, 18vh)' }}
                px={{ lg: 'max(4vw, 40px)' }}
                margin="auto"
                justifyContent="flex-end"
              >
                <Chakra.Text
                  mr="18px"
                  fontSize="18px"
                  textStyle="basisGrotesque"
                >
                  Navigate
                </Chakra.Text>
                <ArrowRightIcon />
              </Chakra.Box>
            </Chakra.Flex>
            <Chakra.Flex
              d={{ base: 'none', lg: 'flex' }}
              flexDirection="column"
              pos="relative"
              width="100%"
              alignSelf={{ lg: 'center' }}
              color="#fff"
            >
              <Chakra.Box
                width="100%"
                pt={{ lg: '9%' }}
                px={{ lg: 'max(4vw, 40px)' }}
                p="40px 40px 60px 40px"
                margin="auto"
              >
                <HomeContent />
              </Chakra.Box>
            </Chakra.Flex>
          </Chakra.Flex>
        </Chakra.Flex>
        <Chakra.Flex
          flexDir="column"
          minH={{ base: '0', lg: '50vh' }}
          d={{ base: 'flex', lg: 'none' }}
          p="40px 40px 120px 40px"
          bg="#002DD1"
          color="#fff"
          alignItems="center"
        >
          <Chakra.Box>
            <HomeContent />
          </Chakra.Box>
        </Chakra.Flex>
      </Chakra.Box>
    </ScrollableSection>
  )
}

export default Home
