import React, { useState } from 'react'
import * as Chakra from '@chakra-ui/react'
import { OpacityIntersection } from '../scroll-animations/opacity-intersection'
import PageInfo from './page-info'
import HeadingTextAnimateSplit from './heading-text-animate-split'

export const LeftText = React.forwardRef(
  (
    {
      titleSm,
      pageNum,
      totalPages,
      titleLg,
      body,
      isLight = false,
      fullWidth = false,
      paddingRight = false,
      isSticky = false,
      isLeft = false,
      ...props
    },
    ref
  ) => {
    return (
      <Chakra.Flex
        width={
          fullWidth
            ? '100%'
            : { base: '100%', lg: '40vw' }
        }
        as={OpacityIntersection}
        paddingRight="0"
        paddingLeft="0"
        {...props}
      >
        <Chakra.Flex
          h={{ base: 'auto', lg: '100%' }}
          flex="1"
          pos="relative"
          ref={ref}
          minHeight={{ lg: '100vh' }}
          alignItems="center"
          justifyContent="center"
        >
          <Chakra.Flex
            p={{
              base: '40px 40px 60px 40px',
            }}
            w={{
              base: '100%',
              lg: '100%',
            }}
            px={{ lg: isLeft ? '4vw' : '5vw' }}
            pos={{ base: 'static', lg: 'relative' }}
            alignItems={'flex-start'}
            flexDirection="column"
            color={isLight ? '#000' : '#fff'}
            justifyContent="center"
          >
            <PageInfo
              numCurrent={pageNum}
              numTotal={totalPages}
              title={titleSm}
              mb="100px"
              color={isLight ? '#000' : '#fff'}
            />

            <HeadingTextAnimateSplit
              my={{
                base: '38px',
                lg: '80px',
                '2xl': '100px',
              }}
              fontSize={{
                base: '42px',
                lg: 'max(90px, 6vw)',
              }}
              mb="50px"
              textStyle="aktivGrotesk"
              line1={titleLg}
            />
            <Chakra.Text
              fontSize={{
                base: '20px',
                lg: '24px',
                xl: '32px',
                '2xl': '32px',
              }}
              opacity="0.6"
              height={{ lg: '20vh' }}
            >
              {body}
            </Chakra.Text>
          </Chakra.Flex>
        </Chakra.Flex>
      </Chakra.Flex>
    )
  }
)

/*
 css={{'@media only screen and (min-width : 1224px)':{
                            paddingTop: '50px !important' ,
                            paddingBottom: '50px !important'
                        }}}
*/
