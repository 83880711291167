import React from 'react'

function PlusIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="23.424"
      height="23.424"
      data-name="Group 157"
      viewBox="0 0 23.424 23.424"
    >
      <path
        fill="#fff"
        d="M0 0H2.928V23.424H0z"
        data-name="Rectangle 240"
        transform="translate(10.248)"
      ></path>
      <path
        fill="#fff"
        d="M0 0H2.928V23.424H0z"
        data-name="Rectangle 241"
        transform="rotate(90 6.588 16.836)"
      ></path>
    </svg>
  )
}

export default PlusIcon
