import React from 'react'

function ArrowDownSmallIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="8.832"
      height="22.092"
      viewBox="0 0 8.832 22.092"
    >
      <g
        fill="#fff"
        data-name="Group 70"
        transform="rotate(90 4.416 4.416)"
      >
        <path
          d="M0 0H21.401V1.381H0z"
          data-name="Rectangle 33"
          transform="translate(0 3.725)"
        ></path>
        <path
          d="M0 0H6.248V1.562H0z"
          data-name="Rectangle 34"
          transform="rotate(45 8.837 21.334)"
        ></path>
        <path
          d="M0 0H6.248V1.562H0z"
          data-name="Rectangle 35"
          transform="rotate(-45 17.613 -16.137)"
        ></path>
      </g>
    </svg>
  )
}

export default ArrowDownSmallIcon
