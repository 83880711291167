import React from 'react'
import * as Chakra from '@chakra-ui/react'
import { useAnimatableLayoutEffect } from '../animation-helpers/use-animatable-layout-effect'
import { getPositionOfElement } from '../animation-helpers/get-position-of-element'
import { uiState } from '../state-machines/ui-state'

export function SlideIntoViewVertical({
  inView,
  sidePanelRef,
  span = '45vw',
  defaultInView = false,
  ...props
}) {
  return (
    <Chakra.Box
      width={{ base: '100%', lg: span }}
      top="0"
      height="100%"
      position={{ base: 'relative', lg: 'fixed' }}
      left={{ lg: '0' }}
      transition="1s cubic-bezier(0.71, 0.02, 0.12, 1.02)"
      transform={{
        base: 'translateY(0px)',
        lg:
          inView || defaultInView
            ? 'translateY(0px)'
            : 'translateY(100%)',
      }}
    >
      <Chakra.Box {...props} />
    </Chakra.Box>
  )
}
