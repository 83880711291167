import React, { useEffect } from 'react'
import * as Chakra from '@chakra-ui/react'
import { LeftText } from '../text-layouts/left-text'
import ScrollableSection from '../dynamic-anchors'
import { useInView } from 'react-intersection-observer'
import { uiState } from '../state-machines/ui-state'
import CircleGif from '../../images/tsl-globe-web.gif'
import LogoIcon from '../icons/logo-icon'
import { useAnimatableLayoutEffect } from '../animation-helpers/use-animatable-layout-effect'
import { getPositionOfElement } from '../animation-helpers/get-position-of-element'

const About = props => {
  let { ref, inView } = useInView({
    threshold: 1,
    initialInView: false,
    triggerOnce: false,
  })

  const [pageTop, setPageTop] = React.useState(0)
  const boxRef = React.useRef()

  const scrollY = uiState.useGlobalState(
    gs => gs.scrollY
  )

  useEffect(() => {
    if (inView) {
      uiState.changeLogoColor('#fff')
    }
  }, [inView])

  useAnimatableLayoutEffect(() => {
    if (boxRef && boxRef.current) {
      let pos = getPositionOfElement(boxRef)
      setPageTop(pos.pageTop)
    }
  }, [inView])

  return (
    <ScrollableSection
      hash={'about'}
      meta={{ title: 'About • TechStreamLab' }}
    >
      <Chakra.Box
        h={{ base: 'auto', lg: '150vh' }}
        maxH={{ base: 'none', lg: '150vh' }}
        ref={boxRef}
      >
        <Chakra.Flex
          flexDir={{ base: 'column', lg: 'row' }}
          maxH={{ base: 'none' }}
          h={{ base: 'auto' }}
          position={{
            base: 'relative',
            lg: scrollY > pageTop ? 'fixed' : 'sticky',
          }}
          top={{ lg: '0' }}
          overflow="hidden"
        >
          <LeftText
            ref={ref}
            titleSm="About"
            pageNum="02"
            totalPages="07"
            titleLg="About."
            body="TSL is a family of tech-focused companies in nascent industries with the aim of pioneering and redefining their respective sectors."
            position={{
              base: 'relative',
              lg: 'sticky',
            }}
            top={{ lg: '0' }}
          />
          <Chakra.Box
            maxH={{ base: '575px', lg: 'none' }}
            position="fixed"
            top={0}
            opacity={inView ? 1 : 0}
            right={{ base: '0', lg: '7vw' }}
            pos={{ base: 'relative', lg: 'fixed' }}
            w={{ base: '100%', lg: '48vw' }}
            css={{
              '@media screen and (max-width: 1279px)': {
                transform: 'none!important',
              },
            }}
            style={{
              top: 0,
              height: '100%',
              transition:
                '1s cubic-bezier(0.71, 0.02, 0.12, 1.02)',
              overflow: 'hidden',
            }}
          >
            <Chakra.Flex
              alignItems="center"
              justifyContent="center"
              width="100%"
              height="100%"
              bg="#002DD1"
            >
              <Chakra.Flex
                alignItems="center"
                justifyContent="center"
                position="relative"
              >
                <Chakra.Image src={CircleGif} />
                <LogoIcon
                  width="280px"
                  fill="#fff"
                  position="absolute"
                  top="0"
                />
              </Chakra.Flex>
            </Chakra.Flex>
          </Chakra.Box>
        </Chakra.Flex>
      </Chakra.Box>
    </ScrollableSection>
  )
}

export default About
