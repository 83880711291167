import React from 'react'
import * as Chakra from '@chakra-ui/react'
import { useInView } from 'react-intersection-observer'

export function OpacityIntersection({
  initialInView = false,
  triggerOnce = false,
  ...otherProps
}) {
  const { ref, inView, entry } = useInView({
    threshold: 0.25,
    delay: 120,
    initialInView:
      typeof window !== 'undefined'
        ? initialInView
        : true,
    triggerOnce,
  })

  return (
    <Chakra.Box
      ref={ref}
      opacity={{ base: 1, lg: inView ? 1 : 0 }}
      transition="2s"
      {...otherProps}
    />
  )
}
