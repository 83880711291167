import React from 'react'
import * as Chakra from '@chakra-ui/react'
import { useAnimatableLayoutEffect } from '../animation-helpers/use-animatable-layout-effect'
import { getPositionOfElement } from '../animation-helpers/get-position-of-element'
import { uiState } from '../state-machines/ui-state'

export function SlideIntoView({
  inView,
  sidePanelRef,
  span = '48vw',
  ...props
}) {
  const [pageTop, setPageTop] = React.useState(0)
  const scrollY = uiState.useGlobalState(
    gs => gs.scrollY
  )

  useAnimatableLayoutEffect(() => {
    if (sidePanelRef && sidePanelRef.current) {
      let pos = getPositionOfElement(sidePanelRef)
      setPageTop(pos.pageTop)
    }
  }, [inView])

  return (
    <Chakra.Box
      width={{ base: '100%', lg: span }}
      top="0"
      height="100%"
      position={{ base: 'relative', lg: 'fixed' }}
      right={{ lg: '7vw' }}
      transition="1s cubic-bezier(0.71, 0.02, 0.12, 1.02)"
      transform={{
        base: 'translateX(0px)',
        lg:
          inView || scrollY > pageTop
            ? 'translateX(0px)'
            : 'translateX(100%)',
      }}
    >
      <Chakra.Box {...props} />
    </Chakra.Box>
  )
}
