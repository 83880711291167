import * as React from 'react'
import * as Chakra from '@chakra-ui/react'
import * as THREE from 'three'

import useWindowSize from '../../hooks/useWindowSize'

const BlueDots = () => {
  const boxEl = React.useRef(null)
  const mouseX = React.useRef(0)
  const mouseY = React.useRef(0)
  const camera = React.useRef(null)
  const renderer = React.useRef(null)

  const { width, height } = useWindowSize()

  function handleMouse(e) {
    const {
      width: boxWidth,
      height: boxHeight,
    } = boxEl.current.getBoundingClientRect()

    if (e.isPrimary === false) return

    mouseX.current = e.clientX - boxWidth / 2
    mouseY.current = e.clientY - boxHeight / 2
    // mouseX.current = 25
    // mouseY.current = 25
  }

  React.useEffect(() => {
    window.addEventListener('mousemove', handleMouse)

    return () =>
      window.removeEventListener(
        'mousemove',
        handleMouse
      )
  })

  React.useEffect(() => {
    if (!camera.current || !renderer.current) return

    const {
      width: boxWidth,
      height: boxHeight,
    } = boxEl.current.getBoundingClientRect()

    camera.current.aspect = boxWidth / boxHeight
    camera.current.updateProjectionMatrix()

    renderer.current.setSize(boxWidth, boxHeight)
  }, [width, height])

  React.useEffect(() => {
    const { current: boxElCurrent } = boxEl

    const {
      width,
      height,
    } = boxElCurrent.getBoundingClientRect()

    // Scene stuff
    const scene = new THREE.Scene()
    camera.current = new THREE.PerspectiveCamera(
      75,
      width / height,
      1,
      10000
    )
    camera.current.position.z = 1000

    // Variables for animations
    let particles,
      count = 0
    const SEPARATION = 100,
      AMOUNTX = 50,
      AMOUNTY = 50
    const numParticles = AMOUNTX * AMOUNTY

    // Start making objects

    const positions = new Float32Array(numParticles * 3)
    const scales = new Float32Array(numParticles)

    let i = 0,
      j = 0

    for (let ix = 0; ix < AMOUNTX; ix++) {
      for (let iy = 0; iy < AMOUNTY; iy++) {
        positions[i] =
          ix * SEPARATION - (AMOUNTX * SEPARATION) / 2 // x
        positions[i + 1] = 0 // y
        positions[i + 2] =
          iy * SEPARATION - (AMOUNTY * SEPARATION) / 2 // z

        scales[j] = 1

        i += 3
        j++
      }
    }

    const geometry = new THREE.BufferGeometry()
    geometry.setAttribute(
      'position',
      new THREE.BufferAttribute(positions, 3)
    )
    geometry.setAttribute(
      'scale',
      new THREE.BufferAttribute(scales, 1)
    )

    particles = new THREE.Points(geometry)

    scene.add(particles)

    scene.background = new THREE.Color(0x002dd1)

    // Start rendering

    renderer.current = new THREE.WebGLRenderer({
      antialias: true,
    })
    renderer.current.setSize(width, height)

    boxElCurrent.appendChild(
      renderer.current.domElement
    )

    boxElCurrent.style.touchAction = 'none'

    let frameId

    const animate = () => {
      frameId = requestAnimationFrame(animate)

      camera.current.position.x +=
        (mouseX.current - camera.current.position.x) *
        0.05
      camera.current.position.y +=
        (-mouseY.current - camera.current.position.y) *
        0.05
      camera.current.lookAt(scene.position)

      const positions =
        particles.geometry.attributes.position.array
      const scales =
        particles.geometry.attributes.scale.array

      let i = 0,
        j = 0

      for (let ix = 0; ix < AMOUNTX; ix++) {
        for (let iy = 0; iy < AMOUNTY; iy++) {
          positions[i + 1] =
            Math.sin((ix + count) * 0.3) * 50 +
            Math.sin((iy + count) * 0.5) * 50

          scales[j] =
            (Math.sin((ix + count) * 0.3) + 1) * 20 +
            (Math.sin((iy + count) * 0.5) + 1) * 20

          i += 3
          j++
        }
      }

      particles.geometry.attributes.position.needsUpdate = true
      particles.geometry.attributes.scale.needsUpdate = true

      renderer.current.render(scene, camera.current)

      count += 0.1
    }
    animate()

    return () => {
      cancelAnimationFrame(frameId)
      if (boxEl?.current) {
        boxEl.current.removeChild(renderer.domElement)
      }
    }
  }, [])

  return (
    <Chakra.Box
      pos="absolute"
      zIndex="0"
      ref={boxEl}
      w="100%"
      h="100%"
      css={{
        canvas: {
          width: '100%!important',
          objectFit: 'cover',
          objectPosition: 'center',
        },
      }}
    />
  )
}

export default BlueDots
