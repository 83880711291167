import React, { useEffect } from 'react'
import * as Chakra from '@chakra-ui/react'
import CompaniesOne from '../../images/companies-one.jpg'
import CompaniesTwo from '../../images/companies-two.jpg'
import CompaniesThree from '../../images/companies-three.jpg'
import CompaniesSliderChild from '../companies-slider-child'
import { LeftText } from '../text-layouts/left-text'
import ScrollableSection from '../dynamic-anchors'
import { useInView } from 'react-intersection-observer'
import { uiState } from '../state-machines/ui-state'
import { SlideIntoView } from '../scroll-animations/slide-into-view'

const Companies = props => {
  const sidePanelRef = React.useRef()

  const scrollDirection = uiState.useGlobalState(
    gs => gs.scrollDirection
  )
  let { ref, inView } = useInView({
    threshold: scrollDirection === 'up' ? 0 : 0.5,
    initialInView: false,
    triggerOnce: false,
  })

  useEffect(() => {
    if (inView) {
      uiState.changeLogoColor('#fff')
    }
  }, [inView])

  return (
    <ScrollableSection
      hash={'companies'}
      meta={{ title: 'Companies • TechStreamLab' }}
    >
      <Chakra.Box
        h={{ base: 'auto', lg: 'auto' }}
        maxH={{ base: 'none' }}
        bg="#000"
        marginLeft={{ lg: '-5vw' }}
      >
        <Chakra.Flex
          flexDir={{
            base: 'column-reverse',
            lg: 'row',
          }}
          height={{ base: '100%', lg: '100%' }}
          ref={sidePanelRef}
        >
          <Chakra.Flex
            pos="relative"
            flex="1"
            width="100%"
            flexDir={{ base: 'column', lg: 'column' }}
            ref={ref}
            justifyContent="flex-end"
          >
            <Chakra.Flex
              zIndex="1"
              flexDir="column"
              width={{ base: '100%', lg: '45vw' }}
              pos={{ base: 'relative' }}
              paddingLeft={{ lg: '5vw' }}
              bgColor="#000"
            >
              <CompaniesSliderChild
                bgImg={CompaniesOne}
                letter="A"
                title="Fanclub Sports Capital"
                to="/companies#fanclub"
                url="https://fanclubsports.co/"
              />
              <CompaniesSliderChild
                bgImg={CompaniesTwo}
                letter="B"
                title="AIP LABS®"
                to="/companies#aip"
                url="https://www.aip.ai/"
              />
            </Chakra.Flex>
          </Chakra.Flex>

          <SlideIntoView
            d="flex"
            pos="relative"
            bg="#002DD1"
            justify="space-between"
            alignItems="flex-end"
            flexDirection="column"
            h="auto"
            color="#fff"
            inView={inView}
            sidePanelRef={sidePanelRef}
          >
            <LeftText
              titleSm="Companies"
              pageNum="05"
              totalPages="07"
              titleLg="Companies."
              body="We invest in people that are building
              disruptive companies that have the ability
              and vision to transform the way things
              are."
              fullWidth
              paddingRight
            />
          </SlideIntoView>
        </Chakra.Flex>
      </Chakra.Box>
    </ScrollableSection>
  )
}

export default Companies
